import { createEnum } from '~/utils/create-enum'

export const e2e = createEnum({
    tela: {
        selectMenu: {
            group: null,
            item: null,
            telaselect: null,
        },
        attributes: {
            attributeItem: null,
            addAttributeButton: null,
            deleteAttributeButton: null,
            attributeTitle: null,
            attributeDescription: null,
            attributeType: null,
        },
    },
    login: {
        email: null,
        password: null,
        button: null,
        error: null,
    },
    dashboard: {
        projectList: {
            grid: null,
            addProjectButton: null,
            projectNameInput: null,
            deleteProjectButton: null,
            confirmDeleteProjectButton: null,
        },
        projectItem: {
            title: null,
            addCanvasButton: null,
            canvasItem: null,
            canvasTitleInput: null,
            deleteCanvasButton: null,
            confirmDeleteCanvasButton: null,
            deleteProjectButton: null,
            promptTitle: null,
            promptItem: null,
        },
    },
    craft: {
        editor: null,
        configurationPane: null,
        configuration: {
            title: null,
            modelSelect: null,
            temperatureSlider: null,
        },
        previewPane: {
            variablesToggle: null,
            chatToggle: null,
            chatInput: null,
            chatActorPicker: null,
            continueChatButton: null,
            runGenerationButton: null,
            configuration: null,
        },
        outputPane: {
            noOutput: null,
            toolOutput: null,
            generationOutput: null,
            structuredOutput: null,
        },
        structuredOutputPane: null,
        structuredOutput: {
            toggleOnOff: null,
            attributesPane: null,
        },
        toolsPane: null,
        tools: {
            addToolButton: null,
            deleteToolButton: null,
            toolName: null,
            toolDescription: null,
        },
    },
    navigation: {
        backButton: null,
        logoutMenu: null,
        tabBar: {
            craft: null,
            test: null,
            evaluation: null,
        },
        avatar: null,
    },
} as const)
