// Define a type that ensures all nested objects are transformed to strings
type EnumResult<T> = {
    [P in keyof T]: T[P] extends Record<string, any> ? EnumResult<T[P]> : string;
}

export function createEnum<T extends Record<string, any>>(obj: T, prefix = ''): EnumResult<T> {
    const result: any = {}
    for (const key in obj) {
        const fullPath = prefix ? `${prefix}.${key}` : key
        if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key]))
            result[key] = createEnum(obj[key], fullPath)
        else
            result[key] = fullPath
    }
    return result as EnumResult<T>
}
